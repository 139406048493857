import Button from '../atoms/Button'
import { Sidenote } from '../atoms/Text'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import Fox from '../../assets/metamask-fox-cleaned.svg'
import { useAccount, useEnsName } from 'wagmi'
import { useEffect } from 'react'
import { log_event } from '../../modules/firebase'
import { log } from '../../modules/helpers'
import { useDisconnectWallets } from '../../hooks/wallets'


export default ( { id, children, onClick, wallet_icon=true, connect_prompt='Connect to wallet', airdrop_tag=false, ...props } ) => {

    // State variables
    const { address, isConnected, isConnecting, connector } = useAccount()
    const { data: ENS } = useEnsName( { address, chainId: 1 } )
    const disconnect = useDisconnectWallets()
    const { openConnectModal } = useConnectModal()

    // Log connector so we know what wallets to be extra nice to
    useEffect( () => {
        if( !connector ) return log( `Not connected` )
        log_event( `wallet_connected`, {
            wallet_name: connector?.name
        } )
    }, [ connector ] )

    return <>
        <Button id={ id } icon={ wallet_icon ? Fox : undefined } onClick={ address ? onClick : openConnectModal } { ...props }>
            { isConnecting && 'Connecting to wallet...' }
            { address && ( children || 'Submit' ) }
            { !isConnected && !address && connect_prompt }
        </Button>
        { address && <Sidenote onClick={ disconnect }>Disconnect { ENS || address?.slice( 0, 10 ) }</Sidenote> }
		
    </>

}