import { erc20ABI, useContractReads } from "wagmi"
import { log } from "../modules/helpers"

const RPL_contract = '0xd33526068d116ce69f19a9ee46f0bd304f21a51f'
const rETH_contract = '0xae78736cd615f374d3085123a210448e74fc6393'

export const useRPBalances = address => {

    const { data: balances } = useContractReads( {
        enabled: !!address,
        contracts: [
            {
                address: RPL_contract,
                abi: erc20ABI,
                functionName: 'balanceOf',
                args: [ address ]
            },
            {
                address: rETH_contract,
                abi: erc20ABI,
                functionName: 'balanceOf',
                args: [ address ]
            }
        ],
        chainId: 1
    } )

    const interpreted_balances = balances?.map( balance => balance?.toString() )

    const [ RPL=0, rETH=0 ] = interpreted_balances || []
    return { RPL, rETH }

}