import { useRef } from "react"
import styled from "styled-components"
import { log } from "../../modules/helpers"

const PrettyCheckbox = styled.div`

	display: flex;
    flex-direction: row;
	cursor: pointer;
	width: 350px;
	max-width: 100%;

	opacity: .6;
	&:hover {
		opacity: 1;
	}

	& label {
		cursor: pointer;
		color: ${ ( { theme } ) => theme.colors.text };
		font-style: italic;
		&[disabled] {
			text-decoration: line-through;
		}
	}
	& input[type=checkbox] {
		cursor: pointer;
		background: ${ ( { theme } ) => theme.colors.backdrop };
		margin-right: 10px;
	}

	span {
		text-decoration: underline;
		cursor: pointer;
	}

`

export default ( { onClick, label, ...props } ) => {

    const { current: internal_id } = useRef( `input-${ Math.random() }` )

    return <PrettyCheckbox onClick={ onClick }>
        <input id={ internal_id } type='checkbox' { ...props } />
        <label { ...props } htmlFor={ internal_id }>
            { label }
        </label>
    </PrettyCheckbox>

}