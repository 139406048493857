import Container from "../atoms/Container"
import Button from "../atoms/Button"
import Hero from "../molecules/Hero"
import { H1, H2, Text } from "../atoms/Text"
import { useContractRead, useContractWrite } from "wagmi"
import { log } from "../../modules/helpers"
const { REACT_APP_progeny_chain_id, REACT_APP_progeny_rocketeer_contract_address } = process.env

export default function Homepage() {

    // Prepare progey erc721 minting function
    const { writeAsync: toggle_minting_status } = useContractWrite( {
        abi: [ {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "toggleMinting",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        } ],
        functionName: 'mint',
        address: REACT_APP_progeny_rocketeer_contract_address,
        chainId: Number( REACT_APP_progeny_chain_id )
    } )

    // Read the progeny mint contract
    const { data: minting_status } = useContractRead( {
        abi: [ {
            "inputs": [],
            "name": "mintingEnabled",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        } ],
        name: "mintingEnabled",
        address: REACT_APP_progeny_rocketeer_contract_address,
        chainId: Number( REACT_APP_progeny_chain_id )
    } )
    log( 'minting_status', minting_status )

    async function toggle_minting() {
        try {
            alert( `Flipping minting toggle...` )
            await toggle_minting_status()
            alert( 'Toggle success' )
        } catch ( error ) {
            log( error )
            alert( error.message )
        }
    }

    return <Container>

        <Hero>
            <H1>Admin cockpit</H1>
            <H2>You can&apos;t do anything here anon</H2>
            <Text>Minting is currently { minting_status === false ? 'disabled' : 'enabled' }</Text>
            <Button onClick={ toggle_minting }>Toggle status</Button>
        </Hero>

    </Container>
}