import { useEffect, useState } from "react"
import { erc721ABI, useAccount, useContractRead, useContractReads } from "wagmi"
import { log } from "../modules/helpers"

const { REACT_APP_OG_rocketeer_contract_address, REACT_APP_og_rocketeer_chain_id=1 } = process.env
const og_contract = {
    address: REACT_APP_OG_rocketeer_contract_address,
    abi: [
        ...erc721ABI,
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "index",
                    "type": "uint256"
                }
            ],
            "name": "tokenOfOwnerByIndex",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function",
            "constant": true
        },
    ]
}
const call_og_contract = {
    ...og_contract,
    chainId: 1
}

export const useOGRocketeerTokenIds = address => {

    // Set address and contract data
    const { address: connected_address } = useAccount()
    address = address || connected_address

    // Get rocketeer balance
    const { data: raw_balance } = useContractRead( {
        enabled: !!address,
        ...call_og_contract,
        functionName: 'balanceOf',
        args: [ address ],
        chainId: REACT_APP_og_rocketeer_chain_id
    } )
    const balance = raw_balance?.toNumber()

    // Get Rocketeer IDs
    const { data: rocketeers } = useContractReads( {
        enabled: balance > 0,
        contracts: Array.from( { length: balance } ).map( ( val, index ) => ( { 
            ...call_og_contract,
            functionName: 'tokenOfOwnerByIndex',
            args: [ address, index ]
        } ) ),
        chainId: REACT_APP_og_rocketeer_chain_id
    } )
    log( `Rocketeer IDs: `, rocketeers )
    const token_ids = rocketeers?.map( id => id?.toNumber() )

    return {
        address: address,
        balance,
        token_ids
    }

}

export const useOGRocketeers = address => {

    const { token_ids, balance } = useOGRocketeerTokenIds( address )
    const [ rocketeers, set_rocketeers ] = useState(  )
    
    // Get metadata
    useEffect( (  ) => {

        
        let cancelled = false;
    
        ( async () => {
    
            try {

                if( typeof balance == 'undefined' ) {
                    set_rocketeers( undefined )
                    return log( `Rocketeer balance undefined, exiting` )
                }
                if( balance == 0 || !token_ids?.length ) {
                    log( `Address has 0 balance for rocketeers, setting to empty array` )
                    return set_rocketeers( [] )
                }
                
                set_rocketeers( undefined )
                log( `Reading OG Rocketeer data of: `, token_ids )
                const rocketeer_data = await fetch( `https://rocketeer.fans/api/rocketeers/?ids=${ token_ids.join( ',' ) }` ).then( res => res.json() )
            
                if( cancelled ) return
                log( `Setting rocketeer state: `, rocketeer_data )
                set_rocketeers( rocketeer_data )
    
            } catch ( e ) {
                log( `Error retreiving metadata: `, e )
                alert( `Error reading OG Rocketeer data: ${ e.message }` )
            }
    
        } )( )
    
        return () => cancelled = true
    
    }, [ token_ids?.length, balance ] )

    return {
        balance,
        rocketeers
    }

}