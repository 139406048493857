import { useEffect, useState } from "react"
import { erc721ABI, useAccount, useContractRead, useContractReads } from "wagmi"
import { log } from "../modules/helpers"
import useInterval from "use-interval"

let { REACT_APP_progeny_rocketeer_contract_address, REACT_APP_progeny_chain_id } = process.env
REACT_APP_progeny_chain_id = Number( REACT_APP_progeny_chain_id )

const progeny_contract = {
    address: REACT_APP_progeny_rocketeer_contract_address,
    abi: [
        ...erc721ABI,
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "owner",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "index",
                    "type": "uint256"
                }
            ],
            "name": "tokenOfOwnerByIndex",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function",
            "constant": true
        },
    ]
}

export const useRocketeerProgeny = id => {

    const [ metadata, set_metadata ] = useState(  )
    const [ refresh_timestamp, set_refresh_timestamp ] = useState(  )

    const rocketeer_uri = id ? `https://progeny.rocketeer.fans/progeny/api?id=${ Number( id ) }` : undefined
    // const { error, data: rocketeer_uri } = useContractRead( {
    //     enabled: !!id,
    //     ...progeny_contract,
    //     chainId: REACT_APP_progeny_chain_id,
    //     functionName: 'tokenURI',
    //     args: [ id ]
    // } )
    // if( error ) log( `Contract read error on ${ progeny_contract?.address } with ID ${ id }: `, error )

    // If metadata has no image yet, keep refreshing the metadata
    useInterval( () => {
        if( !metadata?.image ) set_refresh_timestamp( Date.now() )
    }, metadata?.image ? null : 3000 )

    useEffect( (  ) => {

        let cancelled = false;
    
        ( async () => {
    
            try {
    
                // If no rocketeer uri, exit
                if( !rocketeer_uri ) {
                    log( `No Rocketeer URI for ${ id }, exiting hook` )
                    return
                }

                // If metadata with image is present, exit
                if( metadata?.image ) {
                    log( `Hook already has image ${ metadata?.image }, exiting` )
                    return
                }

                const meta = await fetch( rocketeer_uri ).then( response => response.json() )
                if( cancelled ) return
                log( `Received metadata: `, meta )
                set_metadata( meta )
    
            } catch ( e ) {
                log( `Error fetching metadata: `, e )
            }
    
        } )( )
    
        return () => cancelled = true
    
    }, [ rocketeer_uri, refresh_timestamp ] )

    return metadata

}