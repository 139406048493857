import { useState } from "react"
import { erc721ABI, useAccount, useContractWrite, useSigner } from "wagmi"
import { log } from "../../modules/helpers"
import { useOGRocketeers } from "../../hooks/og_rocketeers"
import Card from "../atoms/Card"
import Checkbox from "../atoms/Checkbox"
import Container from "../atoms/Container"
import Image from "../atoms/Image"
import { H1, H2, Sidenote, Text } from "../atoms/Text"
import Address from "../molecules/Address"
import Hero from "../molecules/Hero"
import Loading from "../molecules/Loading"
import MetamaskButton from "../molecules/MetamaskButton"
import Rocketeer from "../molecules/Rocketeer"
import Button from "../atoms/Button"
import Link from "../molecules/Link"
import Column from "../atoms/Column"
import { useRPBalances } from "../../hooks/rp_balances"
import { useInheritancePreference } from "../../hooks/minting"
import { save_inheritance_preferences } from "../../modules/firebase"
import { sign_action } from '@poap/confirm-with-ethereum'
import { useNavigate } from "react-router-dom"
const { REACT_APP_progeny_chain_id, REACT_APP_progeny_rocketeer_contract_address } = process.env

export default function MintProgeny() {

    const navigate = useNavigate()

    // State management
    const { address } = useAccount()
    const [ step, set_step ] = useState( 1 )
    const total_steps = 3
    const [ loading, set_loading ] = useState(  )
    const { data: signer } = useSigner()

    // Special items
    const [ ancestor, set_ancestor ] = useState(  )

    // Rocketeer OG perks
    const { rocketeers: og_rocketeers } = useOGRocketeers( address )
    const { RPL, rETH } = useRPBalances( address )
    const existing_inheritance_preference = useInheritancePreference( address )

    // Prepare progey erc721 minting function
    const { writeAsync: call_progeny_mint } = useContractWrite( {
        abi: [ {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                }
            ],
            "name": "mint",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        } ],
        functionName: 'mint',
        address: REACT_APP_progeny_rocketeer_contract_address,
        chainId: Number( REACT_APP_progeny_chain_id ),
        args: [ address ]
    } )

    // Calculate community score
    let community_score = 0
    if( RPL != 0 || rETH != 0 ) community_score = 1
    if( RPL != 0 && rETH != 0 ) community_score = 2
    if( og_rocketeers?.length > 0 ) community_score = 3

    // Generate perk object
    const perks = {
        patch: ancestor?.[ 'available outfits' ] > 0,
        edition: ancestor?.[ 'available outfits' ] > 3,
        color: ancestor?.[ 'available outfits' ] > 5
    }

    // Calculate perk count
    let perk_count = 0
    if( perks.patch ) perk_count++
    if( perks.edition ) perk_count++
    if( perks.color ) perk_count++

    const [ inherit_patch, set_inherit_patch ] = useState(  )
    const [ inherit_edition, set_inherit_edition ] = useState(  )
    const [ inherit_color, set_inherit_color ] = useState(  )
    const [ perks_signature, set_perks_signature ] = useState(  )

    /* ///////////////////////////////
    // Component functions
    // /////////////////////////////*/

    // Select and decorate ancestor
    const select_ancestor = id_to_select => {
        const rocketeer = og_rocketeers.find( ( { id } ) => id == id_to_select )
        const annotated_rocketeer = {
            ...rocketeer,
            ...rocketeer.attributes.reduce( ( acc, val ) => {
                const { trait_type, value } = val
                return { ...acc, [trait_type]: value }
            },{} )
        }
        set_ancestor( annotated_rocketeer )
        log( `Ancestor set: `, annotated_rocketeer )
    }

    // Save inheritance preferences
    async function confirm_inheritances() {

        try {

            // Notify user that preferences are 1 per wallet
            if( existing_inheritance_preference ) {
                const agreed = confirm( `🚨 You have an existing inheritance preference on this wallet.\n\nIf you save this one, it will OVERWRITE the existing one. A mint that has not yet completed (e.g. due to low gas fee) will use this new preference instead!` )
                if( !agreed ) throw new Error( `Cancelled, no action was taken.` )
            }
            

            alert( `Your wallet will now prompt you for a signature.\n\nThis DOES NOT MINT your Rocketeer Progeny yet, it only records your inheritance perferences.` )
            set_loading( `Waiting for signature` )
            const message = {
                og_rocketeer_ancestor: Number( ancestor.id ),
                inherit_patch,
                inherit_edition,
                inherit_color
            }

            const signed_action = await sign_action( message, signer, `👋 This signature will confirm the inheritance preferences for your wallet.` )
            log( `Signed: `, signed_action )

            const { data: { error } } = await save_inheritance_preferences( { signed_action } )
            if( error ) throw new Error( `${ error }` )
            set_perks_signature( signed_action )
            set_step( step + 1 )

        } catch ( e ) {
            log( `Error signing inheritances: `, e )
            alert( `Did not save inheritance: ${ e.message }` )
        } finally {
            set_loading( false )
        }

    }

    // Minting function
    async function mint_rocketeer_progeny() {

        try {
            
            set_loading( `Triggering Rocketeer Progeny mint` )
            const { hash: tx_hash } = await call_progeny_mint()
            navigate( `/transaction/${ tx_hash }` )

        } catch ( e ) {
            alert( `${ e.message }` )
        } finally {
            set_loading( false )
        }

    }


    if( loading ) return <Loading message={ loading } />
    if( !address ) return <Container>

        <Hero>
            <H1>Mint a Rocketeer Progeny</H1>
            <H2>Note: bonus perks to wallets that have OG Rocketeers on them</H2>
            <Text>Minting has completed! Progeny are only available on the secondary market now.</Text>
            <Button href='https://opensea.io/collection/rocketeer-progeny'>View on Opensea</Button>
        </Hero>

    </Container>

    
    if( step == 1 ) return <Container>

        <Hero>
            <H1>[{ step }/{ total_steps }] Set ancestry</H1>
            <H2>Specify the lineage of your new Rocketeer Progeny</H2>
            <Text>Click a single OG Rocketeer to select it as ancestor. This will unlock a few benefits for your soon-to-be-minted Rocketeer Progeny, including: higher randomness, inheriting select traits (e.g. the arm patch).</Text>
            <Button onClick={ () => set_step( step + 1 ) }>Proceed without inheritance</Button>
            <Sidenote>You&apos;re connected to <Address>{ address }</Address></Sidenote>
        </Hero>

        <Card width={ ancestor ? '500px' : '100%' } direction={  ancestor || !og_rocketeers?.length  ? 'column' : 'row' }>

            { !og_rocketeers && <Text>Fetching your OG Rocketeers...</Text> }

            { og_rocketeers && !og_rocketeers?.length && <>
                <H2>No OG Rocketeers</H2>
                <Text align='center'>You have no OG Rocketeers on this wallet, this is no problem, though you&apos;ll miss out on some minting bonuses.</Text>
                <Text>Want to get an OG Rocketeer? Popular secondary market places are:</Text>
                <Column direction='row'>
                    <Link margin='0 .5rem' href='https://sudoswap.xyz/#/browse/buy/0xb3767b2033CF24334095DC82029dbF0E9528039d'>🌐 Sudoswap</Link>
                    <Link margin='0 .5rem' href='https://opensea.io/collection/the-rocketeers'>🌐 Opensea </Link>
                    <Link margin='0 .5rem' href='https://blur.io/collection/the-rocketeers'>🌐 Blur </Link>
                </Column>
                <Button margin="4rem 0 0 0" onClick={ () => set_step( step + 1 ) }>Continue to next step</Button>
            </> }
            { !ancestor && og_rocketeers?.map( ( { id, ...rocketeer } ) => <Rocketeer highlight={ id == ancestor } onClick={ () => select_ancestor( id ) } key={ id } rocketeer={ rocketeer } /> ) }
            { ancestor && !perks_signature && <>
            
                <H2>{ ancestor.name }</H2>
                <Image margin='2rem' width='200px' height='200px' src={ ancestor.image } />
                <Text>{ ancestor.name.split( ' ' )[0] } has { ancestor[ 'available outfits' ] } outfits available, which means you can choose to inherit { perk_count } traits!</Text>
                <Text>Please select which properties you want to inherit:</Text>
                
                <Checkbox checked readOnly label='Bonus randomness' />
                <Checkbox onChange={ ( { target } ) => set_inherit_patch( target.checked ) } label={ `Inherit patch ${ ancestor.patch }` } disabled={ !perks.patch } />
                <Checkbox onChange={ ( { target } ) => set_inherit_edition( target.checked ) } label={ `Inherit edition ${ ancestor.edition == 'regular' ? 'OG' : ancestor.edition }` } disabled={ !perks.edition } />
                <Checkbox onChange={ ( { target } ) => set_inherit_color( target.checked ) } label={ `Inherit color ${ ancestor[ 'outfit color' ] }` } disabled={ !perks.color } />

                <MetamaskButton margin="4rem 0 0 0" onClick={ confirm_inheritances }>
                    <Text>Confirm inheritances by signature</Text>
                </MetamaskButton>

            </> }

        </Card>

    </Container>

    if( step == 2 ) return <Container>

        <Hero>
            <H1>[{ step }/{ total_steps }] Review your status</H1>
            <H2>Review your statuses and preferences before minting</H2>
            <Text>The Rocketeers are the unofficial spirit animals of the Rocket Pool community, in keeping with that fact, community members get boosts!</Text>
            <Sidenote>You&apos;re connected to <Address>{ address }</Address></Sidenote>
        </Hero>

        <Card width="500px" direction={  ancestor || !og_rocketeers?.length  ? 'column' : 'row' }>

            <H2>Your community level: { community_score }/3</H2>
            <Text align='center'>You have { RPL } RPL, { rETH } rETH, and { og_rocketeers?.length || 0 } OG Rocketeers. A higher level grants higher randomness.</Text>

            <H2 margin='3rem 0 1rem'>Your preferences:</H2>
            { !perks_signature && <Text align='center'>You have no inheritance preferences set (requires OG Rocketeers).</Text> }
            { perks_signature && <Text align='center'>You enabled the inheritance of: { Object.keys( existing_inheritance_preference ).reduce( ( acc, key ) => {

                if( key.includes( 'inherit_' ) && existing_inheritance_preference[ key ] ) {
                    log( `Adding ${ key }: `, existing_inheritance_preference[ key ] )
                    return `${ acc }, ${ `${ key.replace( 'inherit_', '' ) }` }`
                }

                return acc

            }, 'bonus rarity' ) }.</Text> }

            <Button margin='4rem 0 0' onClick={ () => set_step( step + 1 ) }>Proceed to minting</Button>

        </Card>

    </Container>

    if( step == 3 ) return <Container>

        <Hero>
            <H1>[{ step }/{ total_steps }] Mint your Rocketeer Progeny</H1>
            <H2>Ready to mint with your preferences</H2>
            <Text>Minting costs only gas and is done on Ethereum mainnet.</Text>
            <MetamaskButton onClick={ mint_rocketeer_progeny }>
                <Text>Mint Progeny Rocketeer</Text>
            </MetamaskButton>
        </Hero>


    </Container>
}