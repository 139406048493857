import Container from "../atoms/Container"
import Main from "../atoms/Main"
import Section from "../atoms/Section"
import Loading from "../molecules/Loading"
import { H1, H2, Text } from "../atoms/Text"
import { useParams } from "react-router-dom"
import { useRocketeerProgeny } from "../../hooks/rocketeer_progeny"
import { log } from "../../modules/helpers"
import Card from "../atoms/Card"
import Image from "../atoms/Image"
import Button from "../atoms/Button"
import { useEffect, useState } from "react"
import Input from "../atoms/Input"
const { REACT_APP_progeny_rocketeer_contract_address } = process.env

export default function Viewer() {

    const { rocketeer_id } = useParams()
    const [ selected_id, set_selected_id ] = useState(  )
    const metadata = useRocketeerProgeny( rocketeer_id )
    const { name, description, attributes, image, error } = metadata || {}

    if( !rocketeer_id ) return <Container>
        <Card width="500px">
            <H2>Input a Rocketeer ID</H2>
            <Input placeholder="Rocketeer Progeny ID" value={ selected_id } onChange={ ( { target } ) => set_selected_id( target.value ) } />
            <Button to={ `/viewer/${ selected_id }` }>View Rocketeer Progeny { selected_id }</Button>
        </Card>
    </Container>
    if( !metadata || error ) return <Loading message="Loading data" />

    return <Container>

        <Card width="500px">
            <Image width="200px" height="200px" src={ image } />
            <H2 align="center" margin="2rem 0 0">{ name }</H2>
            <Text align="center">{ description }</Text>
            { attributes?.map( ( { trait_type, value } ) => <Text margin=".1rem 0" key={ trait_type } align="center">{ trait_type }: { value }</Text> ) }
            <Button margin=" 2rem 0" href={ `https://opensea.io/assets/ethereum/${ REACT_APP_progeny_rocketeer_contract_address }/${ rocketeer_id }` }>View on Opensea</Button>
        </Card>

    </Container>
}