import { useEffect, useState } from "react"
import { sanitize_common } from "../modules/web3/validations"
import { listen_to_document } from "../modules/firebase"
import { log } from "../modules/helpers"

export const useInheritancePreference = address => {

    const [ preference, set_preference ] = useState(  )

    useEffect( () => !address ? undefined : listen_to_document( 'wallet_minting_preferences', sanitize_common( address ), data => {
        log( `Existing preference`, data )
        if( data ) set_preference( data )
    } ), [ address ] )

    return preference

}