import { Route, Routes as DOMRoutes } from "react-router-dom"
import Homepage from "../components/pages/Homepage"
import MintProgeny from "../components/pages/MintProgeny"
import Admin from "../components/pages/Admin"
import Transaction from "../components/pages/Transaction"
import Viewer from "../components/pages/Viewer"

export default function Routes() {
    
    return <DOMRoutes>

        <Route exact path='/' element={ <Homepage /> } />
        <Route exact path='/progeny/mint' element={ <MintProgeny /> } />
        <Route exact path="/admin" element={ <Admin /> } />
        <Route exact path="/transaction/:tx_hash" element={ <Transaction /> } />
        <Route exact path="/viewer/:rocketeer_id?" element={ <Viewer /> } />
        

    </DOMRoutes>
}