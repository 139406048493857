import Container from "../atoms/Container"
import Main from "../atoms/Main"
import Section from "../atoms/Section"
import Hero from "../molecules/Hero"
import { H1, H2, Text } from "../atoms/Text"
import Button from "../atoms/Button"

export default function Homepage() {


    return <Container>

        <Hero>
            <H1>Rocketeer Progeny</H1>
            <H2>The decendants of the original Rocketeer NFTs</H2>
            <Text>The Rocketeer Progeny NFTs are a celebration of the Rocket Pool atlas upgrade launch. Just like the original NFTs they are free (gas-only) to mint and are meant as a fun Rocket Pool community NFT.</Text>
            <Button href='https://opensea.io/collection/rocketeer-progeny'>View on Opensea</Button>
            { /* only show the button below if the date is before july 4th 2023 */ }
            { Date.now() < 1688428800000 && <Button href="https://checkout.poap.xyz/128502">Buy the &quot;proof of donation&quot; POAP</Button> }
        </Hero>

    </Container>
}