import { BrowserRouter as Router } from 'react-router-dom'
import Theme from './components/atoms/Theme'
import Web3_provider from './modules/web3/web3_provider'
import Routes from './routes/Routes'

// ///////////////////////////////
// Render component
// ///////////////////////////////
export default function App( ) {

    return <Theme>

        <Web3_provider>
            <Router>

                <Routes />

            </Router>

        </Web3_provider>

    </Theme>

}