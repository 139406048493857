import Container from "../atoms/Container"
import Main from "../atoms/Main"
import Section from "../atoms/Section"
import Hero from "../molecules/Hero"
import { H1, H2, Text } from "../atoms/Text"
import { useNavigate, useParams } from "react-router-dom"
import { useTransaction, useWaitForTransaction } from "wagmi"
import { log } from "../../modules/helpers"
import Button from "../atoms/Button"
import { tx_explorer_link } from "../../modules/web3/chains"
import { useRocketeerProgeny } from "../../hooks/rocketeer_progeny"
import { useEffect } from "react"
import useInterval from "use-interval"
const { REACT_APP_progeny_chain_id } = process.env

export default function Transaction() {

    const navigate = useNavigate()

    const { tx_hash } = useParams()

    // Listen to transaction data
    const { data: tx_data, refetch: refetch_tx } = useTransaction( {
        hash: tx_hash,
        chainId: Number( REACT_APP_progeny_chain_id )
    } )
    const { confirmations, from } = tx_data || {}
    const has_minted = confirmations > 0
    const is_confirmed = confirmations > 5
    log( `Transaction data: `, tx_data )

    // Listen to transaction receipt
    const { data: receipt, refetch: refetch_receipt } = useWaitForTransaction( {
        hash: tx_hash,
        chainId: Number( REACT_APP_progeny_chain_id ),
        confirmations: 3
    } )

    // Get token ID from receipt logs
    const { logs } = receipt || {}
    let token_id = null
    if( logs ) {
        const [ TransferEvent ] = logs || []
        token_id = parseInt( TransferEvent?.topics[ 3 ] )
    }
    log( `Event: `, logs, token_id )

    // Refresh transaction and receipt until confirmations reach 10
    useInterval( () => {
        refetch_tx()
        refetch_receipt()
    }, is_confirmed ? null : 3000 )

    return <Container>

        <Hero>
            <H2>Your mint is { has_minted ? 'complete' : 'pending' }</H2>
            <Text>While you wait for your new Rocketeer to mint, consider buying the &quot;proof of donation&quot; POAP. The proceeds are used to pay for gas fees, additional proceeds split between LogicBeach.eth and mentor.eth. Presumably to be spent on celebratory drinks.</Text>
            <Button href="https://checkout.poap.xyz/128502">Buy the &quot;proof of donation&quot; POAP</Button>
        </Hero>

        <Section align="flex-start">
            <H2>Track your transaction</H2>
            <Text align="left">Transaction { tx_hash.slice( 0, 10 ) } has { confirmations } confirmations. { is_confirmed ? '' : 'Waiting for Rocketeer Progeny to complete minting, this should take a minute or so.' }</Text>
            { is_confirmed ? <Button onClick={ () => navigate( `/viewer/${ token_id }` ) }>View Rocketeer Progeny</Button> : <Button href={ tx_explorer_link( tx_hash, REACT_APP_progeny_chain_id ) }>View on Etherscan</Button> }
        </Section>

    </Container>
}