import { useDisconnect } from "wagmi"

export function useDisconnectWallets( reload_page=true, clear_cache=true ) {

    const { disconnect } = useDisconnect()

    return function() {

        // Defautl wagmi disconnect
        disconnect()

        // Clear localstorage of caches
        if( !window.localStorage ) return
        if( clear_cache ) {
            window.localStorage.removeItem( 'walletconnect' )
            window.localStorage.removeItem( 'wagmi.store' )
            window.localStorage.removeItem( 'wagmi.cache' )
            window.localStorage.removeItem( 'wagmi.wallet' )
        }
        if( reload_page ) window?.location?.reload()

    }


}