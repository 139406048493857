import styled from 'styled-components'
import Column from '../atoms/Column'
import Image from '../atoms/Image'
import { Text } from '../atoms/Text'

const Square = styled( Column )`

    &:hover {
        opacity: .5;
        cursor: pointer;
    }

`

export default ( { rocketeer, ...props } ) => {

    return <Square align='center' padding='1rem' width='100px' { ...props }>
        <Image width='100%'m height='100%' src={ rocketeer.image } />
        <Text>{ rocketeer.name.split( ' ' )[0] }</Text>
    </Square>

}