import styled from 'styled-components'

export const Text = styled.p`
	font-size: 1rem;
	margin: ${ ( { margin="1rem 0" } ) => margin };
	line-height: 1.5rem;
	color: ${ ( { theme, color } ) => color || theme.colors.text };
	text-align: ${ ( { align } ) => align || 'left' };
	overflow-wrap: anywhere;
	background: ${ ( { background='initial' } ) => background };
	display: flex;
	flex-direction: ${ ( { direction } ) => direction || 'column' };
	align-items: ${ ( { align } ) => align || 'center' };
	justify-content: ${ ( { justify } ) => justify || 'center' };
	a {
		color: ${ ( { banner, theme } ) => banner ? theme.colors.primary_invert : theme.colors.text };
		text-decoration: none;
	}
`

export const H1 = styled.h1`
	font-size: 2.5rem;
	font-weight: 500;
	line-height: 1.2;	
	font-family: Helvetica, sans-serif;
	text-align: ${ ( { align } ) => align || 'left' };
	color: ${ ( { theme, color } ) => color || theme.colors.primary };
	overflow-wrap: anywhere;
`

export const H2 = styled.h2`
	font-size: 1.5rem;
	margin: ${ ( { margin='0 0 2rem' } ) => margin };
	line-height: 1.2;
	font-weight: 400;
	text-align: ${ ( { align } ) => align || 'left' };
	color: ${ ( { theme, color } ) => color || theme.colors.accent };
	overflow-wrap: anywhere;
`

export const Sidenote = styled.p`
	color: ${ ( { theme } ) => theme.colors.hint };
	font-style: italic;
	margin-top:  1rem;
	text-align: center;
	overflow-wrap: anywhere;
`

export const Br = styled.span`
	width: 100%;
	margin: 2rem 0;
`
export const Sup = styled.sup`
	overflow-wrap: anywhere;
`

export const A = styled.a`
	font-size: 1rem;
	margin: ${ ( { margin='.2rem 0' } ) => margin };
	line-height: 1.5rem;
	color: ${ ( { banner, theme } ) => banner ? theme.colors.primary_invert : theme.colors.text };
	text-decoration: none;
	padding: ${ ( { banner } ) => banner ? '.5rem 1rem' : 'initial' };
	text-align: ${ ( { align } ) => align || 'left' };
	max-width: 90%;
	overflow-wrap: anywhere;
	&:hover {
		text-decoration: 'underline';
	}
	
`